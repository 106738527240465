.shop-info {
  h2 {
    font-size: 16px;
    margin: 0 0 10px;
  }

  .shop-area {
    display: flex;
  }

  .shop-photo img {
    border: 1px solid #cfcec5;
    object-fit: cover;

    @media only screen and (max-width: $breakpoint-s) {
      height: auto;
      width: 90px;
    }
  }

  .shop-content {
    flex: 1 1 auto;
    margin-left: 14px;
  }

  .shop-name {
    font-size: 14px;
    font-weight: bold;

    a {
      color: $text-color;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .shop-profile {
    font-size: 14px;
    margin-top: 10px;

    > p {
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }

    .shop-link {
      margin-top: 4px;
      text-align: right;

      a {
        color: inherit;
        padding-left: 12px;
        position: relative;

        &::before {
          content: $icon-arrow-right;
          font-family: $font-icon;
          font-size: 18px;
          left: -5px;
          line-height: 1;
          position: absolute;
          top: -2px;
        }
      }
    }
  }

  .shop-photo-item {
    margin-top: 20px;

    .items {
      display: grid;
      gap: 8px;
      grid-template-columns: repeat(3, 1fr);

      li img {
        vertical-align: bottom;

        @media only screen and (max-width: $breakpoint-s) {
          height: auto;
          width: 100%;
        }
      }
    }
  }

  .shop-other {
    font-size: 12px;
    margin: 4px 0 8px;
  }

  .shop-other a {
    color: #000;
    font-weight: bold;
    text-decoration: underline;
  }

  .follow-button {
    position: relative;

    follow-button {
      display: block;
      height: 30px;
      width: 100%;
    }

    .follow-recommend {
      bottom: -10px;
      pointer-events: none;
      position: absolute;
      right: 15px;
    }
  }

  .laws {
    font-size: 14px;
    margin-top: 12px;
    text-align: right;

    a {
      color: inherit;
      padding-left: 12px;
      position: relative;

      &::before {
        content: $icon-arrow-right;
        font-family: $font-icon;
        font-size: 16px;
        left: -5px;
        line-height: 1;
        position: absolute;
        top: -2px;
      }
    }
  }
}
