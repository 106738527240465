// アイコンフォント変数
$font-icon: 'iichi-icon';
$icomoon-font-family: 'iichi-icon' !default;
$icon-vintage: '\f101';
$icon-twitter: '\f102';
$icon-truck: '\f103';
$icon-trash: '\f104';
$icon-times-circle: '\f105';
$icon-sort: '\f106';
$icon-shopping-cart: '\f107';
$icon-share: '\f108';
$icon-search: '\f109';
$icon-question-circle: '\f10a';
$icon-plus-square-o: '\f10b';
$icon-pinterest-p: '\f10c';
$icon-person: '\f10d';
$icon-pencil: '\f10e';
$icon-ordermade: '\f10f';
$icon-newspaper-o: '\f110';
$icon-multiple-img: '\f111';
$icon-minus-square-o: '\f112';
$icon-minus-circle-outline: '\f113';
$icon-message: '\f114';
$icon-list: '\f115';
$icon-link: '\f116';
$icon-line: '\f117';
$icon-line-chart: '\f118';
$icon-jpy: '\f119';
$icon-instagram: '\f11a';
$icon-image: '\f11b';
$icon-iichi-logo: '\f11c';
$icon-home: '\f11d';
$icon-header-search: '\f11e';
$icon-header-search-active: '\f11f';
$icon-header-heart: '\f120';
$icon-header-heart-fill: '\f121';
$icon-header-heart-active: '\f122';
$icon-header-clock: '\f123';
$icon-header-clock-active: '\f124';
$icon-header-cart: '\f125';
$icon-header-cart-active: '\f126';
$icon-gift: '\f127';
$icon-folder-open: '\f128';
$icon-flag: '\f129';
$icon-filter: '\f12a';
$icon-file: '\f12b';
$icon-facebook: '\f12c';
$icon-fa-check: '\f12d';
$icon-fa-bell: '\f12e';
$icon-eye: '\f12f';
$icon-external-link: '\f130';
$icon-exclamation-circle: '\f131';
$icon-exchange: '\f132';
$icon-envelope-square: '\f133';
$icon-envelope-o: '\f134';
$icon-drag-vertical: '\f135';
$icon-dots-vertical: '\f136';
$icon-dots-horizontal: '\f137';
$icon-display: '\f138';
$icon-cross1: '\f139';
$icon-coupon: '\f13a';
$icon-cog: '\f13b';
$icon-chevron-right: '\f13c';
$icon-checkbox-marked-circle: '\f13d';
$icon-check1: '\f13e';
$icon-check: '\f13f';
$icon-calendar: '\f140';
$icon-arrow-right: '\f141';
$icon-arrow-left: '\f142';
$icon-arrow-bottom: '\f143';
