@use 'sass:map';
@import './variables-icons';

// フォントファミリー
/* stylelint-disable value-keyword-case */
$font-serif: 'Hiragino Kaku Gothic Pro', Meiryo, 'MS PGothic', 'Helvetica Neue', Osaka, Arial, sans-serif;
$font-yu-gothic: YuGothic, 'Hiragino Kaku Gothic Pro', Meiryo, 'MS PGothic', 'Helvetica Neue', Osaka, Arial, sans-serif;
$font-avenir: Avenir, YuGothic, 'Hiragino Kaku Gothic Pro', Meiryo, 'MS PGothic', 'Helvetica Neue', Osaka, Arial, sans-serif;
/* stylelint-enable value-keyword-case */

// COLORS

// ブランドカラー
$brand-colors: (
  'cream': #d8b090,
  'beige': #c09478,
  'pink': #f8ece8,
  'salmon': #ff4850,
  'white': #fff,
  'black': #000,
);

@function brand-color($name) {
  @if not map.get($brand-colors, $name) {
    @error "Brand color doesn't exist for the name '#{$name}'";
  }

  @return map.get($brand-colors, $name);
}

$light-gold: #f0e6e0;

// 基本色指定
$black: #000;
$white: #fff;
$light-gray: #efefef;
$blue: #007bff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$dark-red: #ad0008;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;
$active: #10ab88;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;

// 主要色指定
$primary-color: brand-color('beige');
$secondary-color: brand-color('beige');

// バリエーション指定
$gray100: #f7f7f7;
$gray150: #f2f2f2;
$gray180: #eaeaea;
$gray200: #e3e3e3;
$gray300: #dadada;
$gray400: #ccc;
$gray500: #adadad;
$gray600: #7c7c7c;
$gray700: #495057;
$gray800: #343a40;
$gray900: #121212;
$black-alpha10: rgba($black, 0.1);
$black-alpha30: rgba($black, 0.3);

// 用途別指定
$link-text-color: #746a58;
$text-color: $gray900;
$text-color-disabled: $gray600;
$bg-color-disabled: $gray300;
$border-color: $gray200;
$action-button-heart-color: #ec4848;
$action-button-background-color: #fcfaf8;
$block-margin: 24px;
$element-margin: 8px;
$pallets: (
  gray: $border-color,
);
$colors: (
  border: map.get($pallets, gray),
);

// BREAKPOINTS
$breakpoint-s: 639px;
$breakpoint-m: 1023px;
$breakpoint-l: 1280px;
